import React from 'react'
import styled from 'styled-components'
import * as ROUTER from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as HOOK from '../hooks'
import * as IMG from '../images'
import * as ELE from './@elements'
import * as STC from './@styleComponents'
import Button5 from './Button5'

const _size = '5rem'

const Header = (props) => {
  // hook
  const history = ROUTER.useHistory()

  const [menu_nav] = HOOK.useMenuNav()

  return (
    <Container fluid {...props}>
      <BS.Row>
        <BoxCol xs={6}>
          <ELE.Image
            height={_size}
            src={IMG.logo_icon}
            onClick={() => history.replace('/')}
          />
        </BoxCol>

        <RowCol>
          <ELE.View
            width={`calc(100% - ${_size})`}
            height={`calc(${_size} * 0.8)`}
            bg={'#657C89'}
          />
          <ELE.Image src={IMG.menu_icon} height={_size} />

          <List>
            <BS.Row xs={12} sm={12} md={12} lg={12}>
              {menu_nav.map((v) => (
                <BS.Col key={v.id}>
                  <Set {...v} />
                </BS.Col>
              ))}
            </BS.Row>
          </List>
        </RowCol>
      </BS.Row>
    </Container>
  )
}

export default Header

// styled-components
const List = styled.div`
  display: none;
  width: 15rem;
  max-width: 200%;
  padding: 2rem;
  background: #222;
  z-index: 99;
  position: absolute;
  right: 0;
  top: ${_size};
`

const Container = styled(BS.Container)`
  width: 100%;
  height: ${_size};
`

const BoxCol = styled(BS.Col)`
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`

const RowCol = styled(BS.Col)`
  display: flex;
  flex-direction: row;

  :hover ${List} {
    display: flex;
    opacity: 1;
  }
`

const _menufont = '1rem'

const _color = '#51BAD1'

const Set = ({ navs, label }) => {
  // hook
  const history = ROUTER.useHistory()

  const navArr = navs.map((v) => ({
    onClick: () => history.push(v.to ?? v.path),
    ...v,
  }))

  return (
    <STC.MainBox margin={'1rem'}>
      <Box>
        <ELE.Text title={label.tw} />
      </Box>

      <BS.Row xs={1}>
        {navArr.map((v, i) => (
          <BS.Col key={v.id + i}>
            <STC.MainBox margin={`calc(${_menufont} / 3)`}>
              <Button5 label={v.label} fontsize={_menufont} {...v} />
            </STC.MainBox>
          </BS.Col>
        ))}
      </BS.Row>
    </STC.MainBox>
  )
}

const Box = styled.div`
  border-bottom: solid 2px ${_color};
  color: ${_color};
  font-size: 1.3rem;
  padding-left: 0.5rem;
  width: 80%;
`
