import React from 'react'
import styled from 'styled-components'
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'

import * as MIX from '../mixin'
import * as UTIL from '../utils'

const Button6 = ({ children, label = children, fontsize, ...props }) => {
  return (
    <Container fontsize={fontsize}>
      <ButtonBox type='button' label={label} fontsize={fontsize} {...props}>
        {label === '主持律師' && <StyleIcon icon={faChevronLeft} />}
        <div>&nbsp;{label}&nbsp;</div>
        {label !== '主持律師' && <StyleIcon icon={faChevronRight} />}
      </ButtonBox>
    </Container>
  )
}

export default Button6

// styled-components
const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  width: 110%;
  position: relative;
  align-self: center;

  ${({ fontsize = 0 }) => `
    height: calc(${fontsize} * 2);
  `}
`

const StyleIcon = styled(FontAwesomeIcon)`
  ${MIX.transition};
  color: #51bad1;
`

const ButtonBox = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  border: none;
  background-color: transparent;
  color: transparent;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    background-color: #51bad1;
    color: #fff;
  }
  :hover ${StyleIcon} {
    color: #fff;
  }

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-right: calc(${fontsize} / 2);
    padding-left: calc(${fontsize} / 2);
  `}

  ${({ label = '' }) =>
    label === '主持律師'
      ? `
      left: 0;
      `
      : `
      right: 0;
      `}
`
