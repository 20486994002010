import React from 'react'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'
import * as ROUTER from 'react-router-dom'
import * as MIX from '../../../mixin'
import * as HOOK from '../../../hooks'
import { ELE, STC } from '../../../components'

const NewsPage = () => {
  // hook
  const history = ROUTER.useHistory()
  const [news] = HOOK.useNews()
  
  const newArr = news.map((v) => ({
    onClick: () => history.push(`/news/${v.id}`),
    ...v,
  }))

  return (
    <STC.MainBox>
      <ELE.View width={100} ratio={1} bg={'#657C89'}>
        <BoxContainer>
          <BS.Row>
            {newArr.map((v) => {
              return <SetCol key={v.id} xs={12} md={6} lg={4} xl={4} {...v} />
            })}
          </BS.Row>
        </BoxContainer>
      </ELE.View>
    </STC.MainBox>
  )
}

export default NewsPage

// styled-components
const BoxContainer = styled(BS.Container)`
  height: 90%;
  overflow-y: auto;
`

// child components
const SetCol = ({
  label = '專業團隊',
  detail = '擔任多家上市櫃公司、中小型企業、財團法人及政府機關之法律顧問。浩宇法律事務所注重細節，秉持誠信職業綜合能力卓越，於關鍵時刻上，給予重要有利的建議。',
  date = '2021.10.01',
  ...props
}) => {
  return (
    <BoxCol1 {...props}>
      <Box1>
        <ELE.Text title={date} center />
      </Box1>

      <View1 width={100} ratio={1} bg={'#fff'}>
        <ELE.View width={100} height={20}>
          <Box2>
            <ELE.Text title={label} />
          </Box2>
        </ELE.View>
        <ELE.View width={100} height={80}>
          <Box3>
            <ELE.Text title={detail} />
          </Box3>
        </ELE.View>
        {(props.to ?? props.path) && <Overlay />}
      </View1>
    </BoxCol1>
  )
}

const Overlay = styled.div`
  ${MIX.transition};
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
`

const BoxCol1 = styled(BS.Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const View1 = styled(ELE.View)`
  margin-bottom: 10%;
  align-self: center;

  :hover ${Overlay} {
    opacity: 1;
  }
`

const Box1 = styled.div`
  width: 30%;
  padding: 5px;
  background: #fff;
`

const Box = styled.div`
  height: 100%;
  margin: 1.3rem;
  overflow: hidden;
`

const Box2 = styled(Box)`
  font-size: 1.5rem;
  font-weight: 450;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  display: flex;

  color: #51bad1;
`

const Box3 = styled(Box)`
  font-size: 1rem;
`
