import React from 'react'
import styled from 'styled-components'
import * as ROUTER from 'react-router-dom'

import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const BusinessPage = () => {
  // hook
  const businessId = ROUTER.useParams().businessId
  const history = ROUTER.useHistory()

  const [businesses] = HOOK.useBusinesses()

  const business = React.useMemo(
    () => businesses?.find((v) => v.id === businessId),
    [businesses, businessId]
  )

  return (
    <>
      <Box1>
        {businesses.map((v, i) => (
          <Box2 key={v.label + i}>
            <COM.Button2
              label={v.label}
              fontsize={'1.8rem'}
              active={v.id === businessId}
              onClick={() => history.push(`/business/${v.id}`)}
            />
          </Box2>
        ))}
      </Box1>

      <STC.MainBox>
        <COM.Title1 title={business.label} />

        <MainBox1>
          {business.detail.map((v, i) => (
            <React.Fragment key={v + i}>
              <ELE.Text title={`${i + 1}. ${v}`} />
              <br />
            </React.Fragment>
          ))}
        </MainBox1>
      </STC.MainBox>
    </>
  )
}

export default BusinessPage

// styled-components
const Box1 = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Box2 = styled.div`
  padding: 1rem;
`

const MainBox1 = styled(STC.MainBox)`
  font-size: 1.5rem;
  padding-left: 7rem;
`
