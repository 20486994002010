import React from 'react'
import * as ROUTER from 'react-router-dom'

import TeamPage from './TeamPage'
import TeamDetailPage from './TeamDetailPage'
import NodataPage from '../../NodataPage'

const TeamRoute = () => {
  return (
    <ROUTER.Switch>
      <ROUTER.Route path='/team' exact component={TeamPage} />
      <ROUTER.Route path='/team/:teamId' component={TeamDetailPage} />

      <ROUTER.Route component={NodataPage} />
    </ROUTER.Switch>
  )
}

export default TeamRoute
