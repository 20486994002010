import React from 'react'
import * as ROUTER from 'react-router-dom'

import BusinessPage from './BusinessPage'
import BusinessDetailPage from './BusinessDetailPage'
import NodataPage from '../../NodataPage'

const BusinessRoute = () => {
  return (
    <ROUTER.Switch>
      <ROUTER.Route path='/business' exact component={BusinessPage} />
      <ROUTER.Route
        path='/business/:businessId'
        component={BusinessDetailPage}
      />

      <ROUTER.Route component={NodataPage} />
    </ROUTER.Switch>
  )
}

export default BusinessRoute
