import React from 'react'

import * as IMG from '../images'
import { ELE, STC } from '../components'

const NodataPage = () => (
  <STC.Whole>
    <ELE.Image width={50} src={IMG.no_data_icon} />
  </STC.Whole>
)

export default NodataPage
