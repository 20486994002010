import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as MIX from '../../../mixin'
import * as UTIL from '../../../utils'
import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const TeamPage = () => {
    // hook
    const history = useHistory()
    const [teams] = HOOK.useTeams()

    const [mode, setMode] = React.useState(0)

    // data
    const teamArr = teams.map((v) => ({
        onClick: () => history.push(`/team/${v.id}`),
        ...v,
    }))
    const masterTeamArr = teamArr.filter((v) => v.title === '主持律師')
    const seniorTeamArr = teamArr.filter((v) => v.title === '主任律師')
    const juniorTeamArr = teamArr.filter((v) => v.title === '律師' || v.title === '資深律師')

    return (
        <StyleMainBox>
            {
                mode === 0 ? (
                    <>
                        <COM.Title3 title={"主持律師"} />

                        <COM.Button6 label={"律師團隊"} onClick={() => setMode(1)} fontsize={'3rem'} />

                        <MainTeam data={masterTeamArr} />
                    </>
                ) : (
                    <>
                        <COM.Title3 title={"主任律師"} />

                        <COM.Button6 label={"主持律師"} onClick={() => setMode(0)} fontsize={'3rem'} />

                        <SubTeam data={seniorTeamArr} />

                        <COM.Title3 title={"律師團隊"} />

                        <SubTeam data={juniorTeamArr} />
                    </>
                )
            }
        </StyleMainBox>
    )
}

export default TeamPage

// child components
const MainTeam = ({ data }) => {
    return (
        <STC.MainBox>
            <BS.Row>
                {data.map((v) => {
                    return <SetCol key={v.id} xs={12} md={6} width={'100%'} {...v} />
                })}
            </BS.Row>
        </STC.MainBox>
    )
}

const SubTeam = ({ data }) => {
    return (
        <STC.MainBox>
            <BS.Row>
                {data.map((v) => {
                    return (
                        <SetCol
                            key={v.id}
                            xs={12}
                            md={6}
                            lg={4}
                            width={'18rem'}
                            border={'8px black double'}
                            {...v}
                        />
                    )
                })}
            </BS.Row>
        </STC.MainBox>
    )
}
const _radio = 768 / 600

const SetCol = ({ img, label, width, border, onClick, ...props }) => {
    return (
        <BoxCol1 {...props}>
            <View1 width={width} ratio={_radio} border={border} onClick={onClick}>
                <View2>
                    <StyleImage src={img} width={100} ratio={_radio} />
                </View2>

                <Overlay>
                    <View2>
                        <ELE.Text title={label} />
                    </View2>
                </Overlay>
            </View1>
        </BoxCol1>
    )
}

// styled-components
const StyleMainBox = styled(STC.MainBox)`
  display: flex;
  flex-direction: column;
`

const Overlay = styled.div`
  ${MIX.transition};
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
`

const BoxCol1 = styled((props) => {
    const [, otherProps] = UTIL.extractStyleProps(props)

    return <BS.Col {...otherProps} />
})`
  display: flex;
  justify-content: center;
`

const View1 = styled(({ border, ...props }) => {
    return <ELE.View {...props} />
})`
  ${({ width = '1rem' }) => `margin-bottom: calc(${width} / 5);`}
  ${({ border }) => border}

  align-self: center;
  :hover ${Overlay} {
    opacity: 1;
  }

  clip-path: polygon(30% 0%, 100% 0%, 70% 100%, 0% 100%);
`

const View2 = styled.div`
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  display: flex;
  justify-content: center;
  align-items: center;

  clip-path: polygon(30% 0%, 100% 0%, 70% 100%, 0% 100%);
`

const StyleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
