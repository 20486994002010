import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'
import * as ICON from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as STC from '../@styleComponents'
import * as MIX from '../../mixin'
import * as UTIL from '../../utils'

const Image = ({
  src,
  ratio = src?.width / src?.height || 1,
  alt = '404 NOT FOUND',
  placeholder = <Placeholder />,
  onClick,
  ...props
}) => {
  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <STC.Box {...{ onClick, ratio }} {...styleProps}>
      <Container {...{ ratio, src }} {...styleProps}>
        {!src ? (
          placeholder
        ) : (
          <StyledImg
            draggable='false'
            src={src?.src ?? src}
            {...{ alt }}
            {...styleProps}
          />
        )}
      </Container>
    </STC.Box>
  )
}

export default Image

Image.propTypes = {
  alt: PropTypes.string,
  ratio: PropTypes.number,
}

const Placeholder = () => (
  <PlaceholderContainer>
    <FontAwesomeIcon icon={ICON.faImage} />
    <div>NOT FOUND</div>
  </PlaceholderContainer>
)

// styled-components
const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  ${MIX.ratioOut}
  ${MIX.circle}
  ${MIX.rounded}
  ${({ src }) => !src && `border: 1px dashed #ccc;`}
`

const StyledImg = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <BS.Image {...otherProps} />
})`
  ${MIX.ratioIn}
  color: #999977;
`

const PlaceholderContainer = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  ${MIX.ratioIn}
  ${MIX.flexCenter}
  color: black;
  background: #ddd;
`
