import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as UTIL from '../../utils'
import * as MIX from '../../mixin'

const Text = ({ title, children, ...props }) => {
  return <StyleDiv {...props}>{title || children}</StyleDiv>
}

export default Text

Text.propTypes = {
  title: PropTypes.string,
}

const StyleDiv = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  ${({ center }) => center && 'text-align: center;'}
  ${MIX.width}
`
