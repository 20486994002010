import React from 'react'
import * as ROUTER from 'react-router-dom'

import ExternalDetailPage from './ExternalDetailPage'
import ExternalPage from './ExternalPage'
import NodataPage from '../../NodataPage'

const ExternalRoute = () => {
  return (
    <ROUTER.Switch>
      <ROUTER.Route path='/external' exact component={ExternalPage} />
      <ROUTER.Route
        path='/external/court'
        component={ExternalDetailPage}
      />

      <ROUTER.Route component={NodataPage} />
    </ROUTER.Switch>
  )
}

export default ExternalRoute
