import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../utils'
import * as ELE from './@elements'

const _color = '#657C89'
const _color1 = '#74564A'

const Button4 = ({ children, label = children, ...props }) => {
  const _size = `calc(${props.fontsize} * 8 / 5)`
  const _sizeT = `calc(${props.fontsize} / 2.5)`

  const [label1, label2] = [label.slice(0, 1), label.slice(1)]

  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <div>
      <Button type='button' {...props}>
        <Container1 {...styleProps}>
          <ELE.View width={_size} height={_size} bg={_color} circle>
            <StyleText1 title={label1} />
          </ELE.View>

          <ELE.View width={`calc(100% - ${_size})`} height={_size}>
            <StyleText2 title={label2} />
          </ELE.View>
        </Container1>

        <Container2 {...styleProps}>
          <ELE.View width={_size} height={_size} bg={_color1} circle />

          <ELE.View width={`calc(100% - ${_size})`} height={_size}>
            <StyleText2>
              <Box>
                {label}
                <Triangle _sizeT={_sizeT} />
                <Triangle _sizeT={_sizeT} />
              </Box>
            </StyleText2>
          </ELE.View>
        </Container2>
      </Button>
    </div>
  )
}

export default Button4

// styled-components

const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  width: 100%;
  height: 100%;
  border-bottom: solid 2px ${_color};
  display: flex;
  flex-direction: row;

  ${({ fontsize = 0 }) => `
    padding-bottom: calc(${fontsize} / 5);
  `}
`

const Container1 = styled(Container)`
  display: flex;
`

const Container2 = styled(Container)`
  display: none;
`

const StyleText = styled(ELE.Text)`
  position: absolute;
  bottom: 0;
`

const StyleText1 = styled(StyleText)`
  right: 0;
  color: #fff;
`

const Box = styled.div`
  display: flex;
  align-items: center;
`

const StyleText2 = styled(StyleText)`
  text-align: left;
  width: 100%;
`

const Triangle = styled(({ _sizeT, ...props }) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  margin-left: 3px;

  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent ${_color};

  ${({ _sizeT = 0 }) => `
  border-width: ${_sizeT} 0 ${_sizeT} calc(${_sizeT} * 86.6 / 50);
  `};
`

const Button = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  width: 100%;
  border: none;
  background-color: transparent;
  color: #222;
  font-weight: 350;

  :hover ${Container1} {
    display: none;
  }
  :hover ${Container2} {
    display: flex;
  }

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-left: ${fontsize};
    padding-right: ${fontsize};
  `}
`
