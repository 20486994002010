// ========================= modify  =========================
// src, [width, height], [width, height, left, top], [width, height, left, top],...
// ———   —————————————    ————————————————————————    ————————————————————————
// img     img_scale            inner_space1                inner_space2
import { buildImgData as build } from '../utils'

import no_data from './no_data.png'
import back_page from './back_page.png'
export let logo = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/home_logo.png';

export const no_data_icon = build(no_data, [191, 97])
export const logo_icon = build(logo, [308, 142])
export const back_page_icon = build(back_page, [308, 142])

// =========================== raw ===========================

export let tmp_banner = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/home_banner.png';
export let banner_about = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/about_banner.png';
export let banner_business = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/business_banner.png';
export let banner_team = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/lawyer_banner.png';
export let banner_periodical = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/periodical_banner.png';
export let banner_news = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/news_banner.png';
export let banner_external = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/external_banner.png';
export let landing_cover = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/home_right.png';
export let menu_icon = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/menu_icon.png';

export let fb_icon = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/footer_icon.png';
export let temp1 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp1.png';
export let temp2 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp2.png';
export let temp3 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp3.png';
export let temp4 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp4.png';
export let temp5 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp5.png';
export let temp6 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp6.png';
export let temp7 = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/temp7.png';

export let first = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/about_first.png';
export let second = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/about_second.png';
export let third = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/about_third.png';
export let four = 'https://s3.ap-southeast-1.amazonaws.com/lawyer-photos/about_four.png';