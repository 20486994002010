import React from 'react'
import * as ROUTER from 'react-router-dom'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../../hooks'
import * as IMG from '../../../images'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const _fontsize = '1.5rem'

const PeriodicalDetailPage = () => {
  // hook
  const { periodicalId } = ROUTER.useParams()

  const [periodicals] = HOOK.usePeriodicals()

  const periodical = React.useMemo(
    () => periodicals?.find((v) => v.id === periodicalId),
    [periodicals, periodicalId]
  )

  return (
    <STC.MainBox>
      <div></div>

      {periodical && (
        <>
          <COM.Title1 title={periodical.label} tag={periodical.author} icon={IMG.temp4} back_page={IMG.back_page_icon}/>
          <STC.MainBox>
            <BS.Row>
              <BS.Col
                xs={{ offset: 6, span: 5 }}
                sm={{ offset: 6, span: 4 }}
                md={{ offset: 7, span: 3 }}
                xl={{ offset: 8, span: 2 }}
              >
              </BS.Col>
            </BS.Row>
            <ELE.View width={100} ratio={2} bg={'#ccc'}>
              <Box>
                <ELE.Text title={periodical.detail} />
              </Box>
            </ELE.View>
          </STC.MainBox>
        </>
      )}
    </STC.MainBox>
  )
}

export default PeriodicalDetailPage

// styled-components
const Box = styled.div`
  width: 100%;
  height: 100%;

  padding: calc(${_fontsize} * 2);
  font-size: ${_fontsize};
  overflow-y: auto;
`
