import React from 'react'
import * as ROUTER from 'react-router-dom'

import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const PeriodicalPage = () => {
  // hook
  const history = ROUTER.useHistory()

  const [periodicals] = HOOK.usePeriodicals()

  const periodicalArr = periodicals.map((v) => ({
    onClick: () => history.push(`/periodical/${v.id}`),
    ...v,
  }))

  return (
    <STC.MainBox>
      {periodicalArr.map((v, i) => (
        <STC.MainBox key={v.id + i} margin={'3rem'}>
          <ELE.Text title={`－${v.date}－`} width={100} center />
          <COM.Button3 label={v.label} fontsize={'3vw'} {...v} />
        </STC.MainBox>
      ))}
    </STC.MainBox>
  )
}

export default PeriodicalPage
