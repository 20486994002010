import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as ELE from './@elements'
import * as STC from './@styleComponents'
import * as IMG from '../images'

const CostView = styled(ELE.View)`
    @media screen and (max-width: 1000px) {
        height: 50vh;
    }

    @media screen and (max-width: 800px) {
        height: 30vh;
    }

    @media screen and (max-width: 450px) {
        height: 20vh;
    }

    @media screen and (max-width: 350px) {
        height: 15vh;
    }
`
const Banner = ({
    label = { en: 'BUSINESS', tw: '業務項目' },
    bannerImg = IMG.tmp_banner,
}) => {
    return (
        <Container>
            {/* left */}
            <CostView
                width={"50vw"}
                height={"60vh"}
                bg={'#757575'}
                color={'#fff'}
            >
                <TitleBox width={"100%"} style={{paddingRight: 60}}>
                    <ELE.View>
                        <div>
                            <TitleEn>{label.en.toUpperCase()}</TitleEn>
                            <TitleTw>{label.tw}</TitleTw>
                        </div>
                    </ELE.View>
                </TitleBox>
            </CostView>

            {/* right */}
            <ImgBox width={"60vw"}>
                <ELE.Image src={bannerImg} ratio={1448 / 616} />
            </ImgBox>
        </Container>
    )
}

export default Banner

Image.propTypes = {
    label: PropTypes.object,
}

// styled-components
const Container = styled.div`
  width: 100%;
  position: relative;
`

// left
const TitleBox = styled(STC.Box)`
  align-self: flex-start;
`

const Title = styled.div`
  padding: 0.5rem;
`

const TitleEn = styled(Title)`
  font-size: 4rem;
  font-weight: 500;
  width: 20vw;
  border-bottom: solid 0.5rem #5496a2;
    @media screen and (max-width: 1000px) {
        font-size: 3rem;
    }

    @media screen and (max-width: 800px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 350px) {
        font-size: 0.7rem;
    }
`

const TitleTw = styled(Title)`
  font-size: 1.5rem;
    @media screen and (max-width: 550px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 0.6rem;
    }

    @media screen and (max-width: 350px) {
        font-size: 0.3rem;
        white-space: nowrap;
    }
`

// right
const ImgBox = styled(STC.Box)`
  position: absolute;
  top: 0;
  right: 0;
`
