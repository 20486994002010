import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../../utils'
import * as MIX from '../../mixin'

export const Whole = styled.div`
  ${MIX.full}
  ${MIX.flexCenter}
`

export const MainBox = styled((props) => {
    const [, otherProps] = UTIL.extractStyleProps(props)

    return <div {...otherProps} />
})`
    font-size: 2.3rem;
    ${({ margin = '5rem' }) => `
        margin-top: ${margin};
        margin-bottom:  ${margin};
    `}

    @media screen and (max-width: 550px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 350px) {
        font-size: 1rem;
    }
`

export const Box = styled((props) => {
    const [, otherProps] = UTIL.extractStyleProps(props)

    return <div {...otherProps} />
})`
  ${MIX.width}
  ${MIX.height}
  ${MIX.circle}
  ${MIX.rounded}
`
