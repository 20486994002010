import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as HOOK from '../hooks'
import * as IMG from '../images'
import * as COM from '../components'
import { ELE } from '../components'

const LandingPage = () => {
  // hooks
  const history = useHistory()
  const [navs] = HOOK.useNavs()

  const navTabArr = navs
    .filter((v) => v.key !== 'external')
    .map((v) => ({
      onClick: () => history.push(v.to ?? v.path),
      ...v,
    }))

  return (
    <BS.Row noGutters>
      <BS.Col xs={12} md={4}>
        <Navbar className='d-flex flex-row flex-md-column' expand={'md'}>
          {/* logo */}
          <BS.Navbar.Brand className='align-self-start'>
            <ELE.Image height={'15vw'} src={IMG.logo_icon} />
          </BS.Navbar.Brand>

          {/* nav */}
          <NavbarToggle aria-controls='left-nav'>
            <BS.Image src={IMG.menu_icon} fluid />
          </NavbarToggle>

          <BS.Navbar.Collapse id='left-nav'>
            <BS.Nav style={{ height: '100%' }}>
              <TabRow>
                {navTabArr.map(({ key, label, ...v }) => (
                  <TabCol
                    key={key}
                    xs={6}
                    sm={4}
                    md={{ offset: 1, span: 11 }}
                    lg={{ offset: 2, span: 8 }}
                  >
                    <COM.Button1 label={label.tw} fontsize={'3vmax'} {...v} />
                  </TabCol>
                ))}
              </TabRow>
            </BS.Nav>
          </BS.Navbar.Collapse>
        </Navbar>
      </BS.Col>

      <BS.Col xs={12} md={8}>
        <ELE.Image src={IMG.landing_cover} ratio={1542 / 1260} />
      </BS.Col>
    </BS.Row>
  )
}

export default LandingPage

// styled-components
const Navbar = styled(BS.Navbar)`
  height: 100%;
  /* display: flex;
  flex-direction: column; */
`

const NavbarToggle = styled(BS.Navbar.Toggle)`
  width: 8rem;
  border: none;
`

const TabRow = styled(BS.Row)`
  height: 100%;
  justify-content: space-evenly;
  /* align-items: flex-end; */
`

const TabCol = styled(BS.Col)`
  display: flex;
  justify-content: center;
`
