import React from 'react'
import * as ROUTER from 'react-router-dom'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as MIX from '../../../mixin'
import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const TeamDetailPage = () => {
  // hook
  const teamId = ROUTER.useParams().teamId
  const history = ROUTER.useHistory()

  const [teams] = HOOK.useTeams()

  const team = React.useMemo(
    () => teams?.find((v) => v.id === teamId),
    [teams, teamId]
  )

  return (
    <STC.MainBox>
      <BoxRow1>
        <BS.Col
          xs={12}
          md={{ offset: 5, span: 7 }}
          lg={{ offset: 6, span: 6 }}
          xl={{ offset: 7, span: 5 }}
        >
          <Box1>
            {teams.map((v, i) => (
              <Box2 key={v.label + i}>
                <Set
                  fontsize={'2vw'}
                  onClick={() => history.push(`/team/${v.id}`)}
                  imgsrc={v.img ?? v.img2}
                  {...v}
                />
              </Box2>
            ))}
          </Box1>
        </BS.Col>
      </BoxRow1>

      {team && (
        <>
          <COM.Title1 title={`${team.title}－${team.label}`} />

          <BS.Row>
            <BS.Col xs={12} lg={6} xl={5}>
              <MainBox1>
                <COM.Title2 title={'學經歷'} />
                <BS.Row>
                  {team.education.map((v, i) => (
                    <BS.Col key={v + i} xs={6}>
                      <ELE.Text title={v} />
                      &nbsp;
                    </BS.Col>
                  ))}
                </BS.Row>
              </MainBox1>

              <MainBox2>
                <COM.Title2 title={'專業領域'} />
                {team.field.map((v, i) => (
                  <React.Fragment key={v + i}>
                    {i > 0 && <ELE.Text title={'、'} />}
                    <ELE.Text title={v} />
                  </React.Fragment>
                ))}
              </MainBox2>
            </BS.Col>

            <BS.Col
              xs={12}
              lg={{ offset: 1, span: 5 }}
              xl={{ offset: 2, span: 4 }}
            >
              <STC.MainBox>
                <ELE.Image
                  width={'100%'}
                  ratio={768 / 1214}
                  src={team.img2 ?? team.img}
                />
              </STC.MainBox>
            </BS.Col>
          </BS.Row>
        </>
      )}
    </STC.MainBox>
  )
}

export default TeamDetailPage

// styled-components
const _size = '8rem'

const BoxRow1 = styled(BS.Row)`
  padding-bottom: 2rem;
`

const Box1 = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 2.5px #111;
  height: ${_size};
  overflow-y: hidden;
  overflow-x: auto;
`

const Box2 = styled.div`
  padding: 0.5rem;
`

const MainBox1 = styled(STC.MainBox)`
  font-size: 1.5rem;
`

const MainBox2 = styled(STC.MainBox)`
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
`

// child components

const Set = ({ imgsrc, label, fontsize, ...props }) => {
  return (
    <BoxCol1 {...props}>
      <View1 height={`calc(${_size} * 0.9)`} ratio={768 / (1214 / 1.3)}>
        <ELE.Image src={imgsrc} width={70} ratio={1} circle top />
        <ELE.Text title={label} />

        <Overlay></Overlay>
      </View1>
    </BoxCol1>
  )
}

const Overlay = styled.div`
  ${MIX.transition};
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(40, 100, 100, 0.3);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
`

const BoxCol1 = styled(BS.Col)`
  display: flex;
  justify-content: center;
`

const View1 = styled(ELE.View)`
  display: flex;
  justify-content: center;
  align-items: center;

  :hover ${Overlay} {
    opacity: 1;
  }
`
