import chroma from 'chroma-js'

export const modifyUrl = (url) =>
  url.slice(-1) === '/' ? url.slice(0, -1) : url

export const modifySize = (size = 1, type = '') => {
  switch (typeof size) {
    case 'number':
      return `${size}${type}`
    case 'string':
      if (size.includes('calc')) {
        return size
      } else {
        const numofSize = parseFloat(size)
        const typeofSize = size.replace(numofSize, '') || type
        return numofSize + typeofSize
      }
    default:
      switch (type) {
        case 'rem':
          return '1rem'
        case '%':
          return '100%'
        default:
          return 1
      }
  }
}

export const toPx = (size = 12) => modifySize(size, 'px')

export const toRem = (size = 1) => modifySize(size, 'rem')

export const toPercent = (size = 100) => modifySize(size, '%')

export const modifyColor = ({ theme, color, bgColor, ...themeProps }) => {
  if (color) return color

  for (const key in themeProps) {
    if (theme[key]) {
      return theme[key]
    }
  }

  const dark = chroma('#000')
  const light = chroma('#fff')
  const chromabg = bgColor && chroma(bgColor)
  return chromabg && (chromabg.get('lab.l') < 70 ? light.hex() : dark.hex())
}

export const modifyCalc = (operator = '-', item = []) => {
  let returnString = '100%'

  for (let i = 0; i < item.length; i++) {
    returnString = `${returnString} ${operator} ${item[i]}`
  }

  return returnString
}

export const toArr = (arg) => (Array.isArray(arg) ? arg : [arg])

export * from './imageHelpers'
export * from './propsHelpers'
export * from './convertSize'
