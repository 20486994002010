import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as STC from '../@styleComponents'
import * as MIX from '../../mixin'
import * as UTIL from '../../utils'

const View = ({ ratio = 1, children, top, bottom, ...props }) => {
  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <STC.Box {...{ ratio }} {...props}>
      <Container {...{ ratio }} {...styleProps}>
        <Inner {...{ top, bottom }}>{children}</Inner>
      </Container>
    </STC.Box>
  )
}

export default View

View.propTypes = {
  ratio: PropTypes.number,
}

// styled-components
const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  ${MIX.ratioOut}
  ${MIX.background}
  ${MIX.color}
  ${MIX.circle}
  ${MIX.rounded}
`

const Inner = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  ${MIX.flexCenter};
  ${MIX.ratioIn};
`
