import React from 'react'
import styled from 'styled-components'
import * as ROUTER from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../hooks'
import * as COM from '../../components'
// Pages
import NodataPage from '../NodataPage'

const MainRoute = () => {
  const [navs] = HOOK.useNavs()

  return (
    <>
      <StyleView>
        <COM.Header />
      </StyleView>

      <ROUTER.Switch>
        {navs.map(({ key, path, ...v }) => {
          return (
            <ROUTER.Route key={key} path={path}>
              <COM.Banner {...v} />

              <Main fluid>
                <v.component />
              </Main>
            </ROUTER.Route>
          )
        })}

        <ROUTER.Route component={NodataPage} />
      </ROUTER.Switch>
    </>
  )
}

export default MainRoute

// style components
const Main = styled(BS.Container)`
  width: 90%;
  /* height: calc(100% - 4.65rem); */
  /* overflow-y: auto; */
`

const StyleView = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  /* border-bottom: darkcyan solid 1px; */
  /* box-shadow: 0px 3px rgba(0, 153, 153, 0.5); */
`
