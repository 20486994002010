export const buildImgData = (image, scale = [], ...inner) => {
  const [width, height] = scale
  const ratio = width / height

  const inner_space = inner.map((v) => ({
    width: v[0],
    height: v[1],
    left: v[2],
    top: v[3],
  }))

  return { src: image, width, height, inner_space, ratio }
}
