import React from 'react'
import * as ROUTER from 'react-router-dom'

import PeriodicalPage from './PeriodicalPage'
import PeriodicalDetailPage from './PeriodicalDetailPage'
import NodataPage from '../../NodataPage'

const PeriodicalRoute = () => {
  return (
    <ROUTER.Switch>
      <ROUTER.Route path='/periodical' exact component={PeriodicalPage} />
      <ROUTER.Route
        path='/periodical/:periodicalId'
        component={PeriodicalDetailPage}
      />

      <ROUTER.Route component={NodataPage} />
    </ROUTER.Switch>
  )
}

export default PeriodicalRoute
