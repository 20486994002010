import './App.css'
import React from 'react'
import styled from 'styled-components'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import * as ROUTER from 'react-router-dom'

import * as PAGE from './pages'
import * as MIX from './mixin'
import * as THEME from './configs/themes'
import * as COM from './components'
import * as HOOK from './hooks'

const App = () => {
  return (
    <ThemeProvider theme={THEME.defaultTheme}>
      <HOOK.DataProvider>
        <GlobalStyle />

        <Container fluid>
          <ROUTER.BrowserRouter>
            <ROUTER.Switch>
              <ROUTER.Route path='/' exact component={PAGE.LandingPage} />
              <ROUTER.Route path='/' component={PAGE.MainRoute} />

              <ROUTER.Route component={PAGE.NodataPage} />
            </ROUTER.Switch>
          </ROUTER.BrowserRouter>

          <COM.Footer />
        </Container>
      </HOOK.DataProvider>
    </ThemeProvider>
  )
}

export default App

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing:border-box;
  }
  html {
    font-size: 14px;
  }
  html,body,#root {
    ${MIX.full};
  }
  img,button {
    ${MIX.nonSelect};
  }
  textarea,input,button {
    :focus {
      outline: none;
    }
  }
  button {
    ${MIX.transition};
  }
`

// styled-components
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`
