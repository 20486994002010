import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../utils'
import * as ELE from './@elements'

const _color = '#51bad1'

const Button2 = ({ children, label = children, ...props }) => {
  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <div>
      <Button type='button' {...props}>
        <Container {...styleProps}>
          <ELE.Text>&nbsp;&nbsp;{label}&nbsp;&nbsp;</ELE.Text>
        </Container>
      </Button>
    </div>
  )
}

export default Button2

// styled-components
const Button = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  border: none;
  background-color: transparent;
  border-radius: 2rem;
  color: #222;

  :hover {
    background-color: ${_color};
    border-color: ${_color};
    color: #fff;
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${_color};
    border-color: ${_color};
    color: #fff;
    border-radius: 2rem;
    `}

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-left: ${fontsize};
    padding-right: ${fontsize};
  `}
`

const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  width: 100%;
  height: 100%;
  border-bottom: solid 2px ${_color};
  display: flex;
  flex-direction: row;

  ${({ fontsize = 0 }) => `
  border-bottom: solid calc(${fontsize} / 8) ${_color};
  `}
`
