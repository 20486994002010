import React from 'react'
import styled from 'styled-components'
import * as ROUTER from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { STC } from '../../../components'

const BusinessPage = () => {
  // hook
  const history = ROUTER.useHistory()

  const [businesses] = HOOK.useBusinesses()

  return (
    <STC.MainBox>
      <COM.Title1 title={'服務項目'} />

      <STC.MainBox>
        <BS.Row>
          {businesses.map((v, i) => (
            <Box2 key={v.label + i} xs={12} md={6} xl={4}>
              <COM.Button2
                label={v.label}
                fontsize={'2rem'}
                onClick={() => history.push(`/business/${v.id}`)}
              />
            </Box2>
          ))}
        </BS.Row>
      </STC.MainBox>
    </STC.MainBox>
  )
}

export default BusinessPage

// styled-components
const Box2 = styled(BS.Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
`
