import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../utils'
import * as ELE from './@elements'

const _color = '#657C89'

const Button3 = ({ children, label = children, ...props }) => {
  const _size = `calc(${props.fontsize} * 8 / 5)`

  const [label1, label2] = [label.slice(0, 1), label.slice(1)]

  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <div>
      <Button type='button' {...props}>
        <Container {...styleProps}>
          <StyleView width={_size} height={_size}>
            <StyleText1 title={label1} />
          </StyleView>

          <ELE.View width={`calc(100% - ${_size})`} height={_size}>
            <StyleText2 title={label2} />
          </ELE.View>
        </Container>
      </Button>
    </div>
  )
}

export default Button3

// styled-components
const StyleView = styled(ELE.View)`
  background-color: ${_color};
  border-radius: 100%;
`

const Button = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  width: 100%;
  border: none;
  background-color: transparent;
  color: #222;
  font-weight: 350;

  :hover {
    background-color: ${_color};
    color: #fff;
  }
  :hover ${StyleView} {
    background-color: transparent;
    border-radius: 0;
  }

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-left: ${fontsize};
    padding-right: ${fontsize};
    border-radius: ${fontsize};
  `}
`

const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  width: 100%;
  height: 100%;
  border-bottom: solid 2px ${_color};
  display: flex;
  flex-direction: row;

  ${({ fontsize = 0 }) => `
    padding-bottom: calc(${fontsize} / 5);
  `}
`

const StyleText = styled(ELE.Text)`
  position: absolute;
  bottom: 0;
`

const StyleText1 = styled(StyleText)`
  right: 0;
  color: #fff;
`

const StyleText2 = styled(StyleText)`
  text-align: left;
  width: 100%;
`
