import React from 'react'
import * as ROUTER from 'react-router-dom'
import styled from 'styled-components'

import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { STC } from '../../../components'
import * as BS from 'react-bootstrap'

const ExternalDetailPage = () => {
  // hook
  const { externalId } = ROUTER.useParams()

  const [external] = HOOK.useExternals()

  const externalLinks = React.useMemo(
    () => external?.filter((v) => v.type === "link"),
    [external, externalId]
  )

  return (
    <STC.MainBox>
      <div></div>
      <COM.Title1 title="地方法院"/>
      <STC.MainBox>
        <BS.Row>
          {
            externalLinks.filter((link) => link.description === "地方法院").map((link) => (
              <>
                <Box2 key={link.id} xs={6} md={4} xl={3}>
                  <COM.Button2
                    label={link.title}
                    fontsize={'1.3rem'}
                    onClick={() => window.open(link.path)}
                  />
                </Box2>
              </>
            ))
          }
        </BS.Row>
      </STC.MainBox>
      <COM.Title1 title="高等法院"/>
      <STC.MainBox>
        <BS.Row>
          {
            externalLinks.filter((link) => link.description === "高等法院").map((link) => (
              <>
                <Box2 key={link.id} xs={6} md={4} xl={3}>
                  <COM.Button2
                    label={link.title}
                    fontsize={'1.3rem'}
                    onClick={() => window.open(link.path)}
                  />
                </Box2>
              </>
            ))
          }
        </BS.Row>
      </STC.MainBox>
    </STC.MainBox>
  )
}

export default ExternalDetailPage

const Box2 = styled(BS.Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`