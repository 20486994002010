import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../utils'

const Button1 = ({ children, label = children, ...props }) => {
  return (
    <div>
      <Button type='button' {...props}>
        {label}
      </Button>
    </div>
  )
}

export default Button1

// styled-components
const Button = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  border: none;
  border-bottom: solid 0.3rem #888;
  background-color: transparent;

  :hover {
    background-color: #555;
    border-color: #555;
    color: #fff;
  }

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-right: calc(${fontsize} * 2);
    padding-left: calc(${fontsize} / 2);
    :hover {
      padding-right: calc(${fontsize} / 2);
      padding-left: calc(${fontsize} * 2);
    }
  `}
`
