import React from 'react'
import TYPES from '../configs/proptypes'

const styleChart = {
  shap: ['circle', 'rounded'],
  scale: ['width', 'height', 'ratio'],
  position: ['bottom', 'top', 'margin', 'center'],
  state: ['active'],
  other: ['bg', 'color', 'fontsize', 'bannerImg', 'img', 'component', 'title'],
  all: [],
}

for (const key in styleChart) {
  styleChart.all = [...styleChart.all, ...styleChart[key]]
}

export const extractStyleProps = (props, types = TYPES.ALL) => {
  const styleProps = {}
  const otherProps = {}

  for (const key in props) {
    if (styleChart[types].includes(key)) {
      styleProps[key] = props[key]
    } else {
      otherProps[key] = props[key]
    }
  }

  return [styleProps, otherProps]
}

export const removeProps = (Component) => {
  const ComponentRemoveStyleProps = (props) => {
    const [, otherProps] = extractStyleProps(props)

    return <Component {...otherProps} />
  }

  return ComponentRemoveStyleProps
}
