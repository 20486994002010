import React from 'react'
import styled from 'styled-components'

import * as UTIL from '../utils'
import * as ELE from './@elements'

const _color = '#5F9EA0'
const _color1 = '#aaa'

const Button5 = ({ children, label = children, ...props }) => {
  const _size = `calc(${props.fontsize} * 8 / 5)`
  const _sizeT = `calc(${props.fontsize} / 2.5)`

  const [styleProps] = UTIL.extractStyleProps(props)

  return (
    <Button type='button' {...props}>
      <Container {...styleProps}>
        <Triangle _sizeT={_sizeT} />

        <ELE.View width={`calc(100% - ${_sizeT})`} height={_size}>
          <StyleText title={label} />
        </ELE.View>
      </Container>
    </Button>
  )
}

export default Button5

// styled-components
const Container = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ fontsize = 0 }) => `
    padding-bottom: calc(${fontsize} / 5);
  `}
`

const StyleText = styled(ELE.Text)`
  position: absolute;
  bottom: 0;
  text-align: left;
  width: 100%;
`

const Triangle = styled(({ _sizeT, ...props }) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <div {...otherProps} />
})`
  margin-right: 0.5rem;

  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent ${_color};

  ${({ _sizeT = 0 }) => `
  border-width: ${_sizeT} 0 ${_sizeT} ${_sizeT};
  `};
`

const Button = styled((props) => {
  const [, otherProps] = UTIL.extractStyleProps(props)

  return <button {...otherProps} />
})`
  width: 100%;
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 350;

  :hover ${Triangle} {
    margin-right: 1rem;
    border-color: transparent transparent transparent ${_color1};
  }

  :hover {
    color: #00BCD4;
  }

  ${({ fontsize = 0 }) => `
    font-size: ${fontsize};
    padding-left: ${fontsize};
    padding-right: ${fontsize};
  `}
`
