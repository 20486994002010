import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as ELE from './@elements'

const _fontsize = '2.8rem'

const Title1 = ({ title }) => {
  return (
    <Container>
      <StyleView width={100} height={50} />
      <StyleText title={title} />
      <StyleView width={100} height={50} />
    </Container>
  )
}

export default Title1

Title1.propTypes = {
  title: PropTypes.string,
}

// styled-components
const Container = styled.div`
  width: 85%;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
`

const StyleView = styled.div`
  background: #51636e;

  flex-grow: 1;
  height: 0.5rem;
`

const StyleText = styled(ELE.Text)`
  font-size: ${_fontsize};
  font-weight: 500;
  padding: ${_fontsize};
`
