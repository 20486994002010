import React from 'react'
import * as ROUTER from 'react-router-dom'

import NewsPage from './NewsPage'
import NewsDetailPage from './NewsDetailPage'
import NodataPage from '../../NodataPage'

const NewsRoute = () => {
  return (
    <ROUTER.Switch>
      <ROUTER.Route path='/news' exact component={NewsPage} />
      <ROUTER.Route
        path='/news/:newsId'
        component={NewsDetailPage}
      />

      <ROUTER.Route component={NodataPage} />
    </ROUTER.Switch>
  )
}

export default NewsRoute
