import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as ELE from './@elements'

const _fontsize = '1.8rem'

const Title1 = ({ title }) => {
  return (
    <Container>
      <StyleText title={title} />
      <StyleView width={100} height={50}></StyleView>
    </Container>
  )
}

export default Title1

Title1.propTypes = {
  title: PropTypes.string,
}

// styled-components
const Container = styled.div`
  width: 85%;
  height: auto;

  font-size: ${_fontsize};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${_fontsize};
`

const StyleView = styled.div`
  background: #4095a5;

  flex-grow: 1;
  height: 2px;
`

const StyleText = styled(ELE.Text)`
  background: #4095a5;
  color: #fff;
  padding-left: ${_fontsize};
  padding-right: calc(${_fontsize} * 1.3);
`
