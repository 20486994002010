import React from 'react'
import * as ROUTER from 'react-router-dom'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../../hooks'
import * as COM from '../../../components'
import { STC } from '../../../components'
import styled from 'styled-components'

const CustomMainBox = styled(STC.MainBox)`
    margin: 2rem 0;
    white-space: nowrap;
    font-size: 4vw;
    @media screen and (min-width: 1000px) {
        font-size: 2vw;
    }

`
const ExternalPage = () => {
    // hook
    const history = ROUTER.useHistory()

    const [externals] = HOOK.useExternals()

    const externalArr = externals.filter(ext => ext.type === "main").map((v) => ({
        onClick: () => {
            if (v.path !== null) {
                window.open(v.path);
            }
            else {
                history.push("/external/court")
            }
        },
        ...v
    }))

    return (
        <STC.MainBox>
            <BS.Row>
                {externalArr.map((v, i) => (
                    <BS.Col
                        key={v.id + i}
                        xs={{ offset: 0, span: 12 }}
                        sm={{ offset: 0, span: 12 }}
                        md={{ offset: 0, span: 12 }}
                        lg={{ offset: 0, span: 6 }}
                        xl={{ offset: 0, span: 6 }}
                    >
                        <CustomMainBox>
                            <COM.Button4 label={v.title} fontsize={'1em'} {...v} />
                        </CustomMainBox>
                    </BS.Col>
                ))}
            </BS.Row>
        </STC.MainBox>
    )
}

export default ExternalPage
