import { css } from 'styled-components'
import * as UTIL from '../utils'

// temp
global.BBB = function BBB(color = 'black') {
  return css`
    border: none 1px ${color};
    border: solid 1px ${color};
  `
}

// layout
export const width = css`
  width: ${({ width, height, ratio }) => {
    if (width) {
      return `${UTIL.toPercent(width)}`
    }

    if (
      typeof height === 'string' &&
      ratio &&
      ['px', 'rem', 'vw'].some((v) => height.includes(v))
    ) {
      return `calc(${height} * ${ratio})`
    }

    return null
  }};
`

export const height = css`
  height: ${({ width, height, ratio }) => {
    if (height) {
      return `${UTIL.toPercent(height)}`
    }

    if (
      typeof width === 'string' &&
      ratio &&
      ['px', 'rem', 'vw'].some((v) => width.includes(v))
    ) {
      return `calc(${width} / ${ratio})`
    }

    return null
  }};
`

export const full = css`
  height: 100%;
  width: 100%;
`

export const flexCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ top }) => top && 'justify-content: flex-start;'}
  ${({ bottom }) => bottom && 'justify-content: flex-end;'}
`

// ratio
export const ratioOut = css`
  overflow: hidden;
  position: relative;

  ${({ ratio, height }) =>
    !height
      ? `width: 100%; padding-bottom: ${100 / ratio}%;`
      : `height: 100%; padding-right: ${100 / ratio}%;`};
`

export const ratioIn = css`
  margin: auto;

  ${({ top }) => top && 'margin-top: 0;'};
  ${({ bottom }) => bottom && 'margin-bottom: 0;'};

  position: absolute;
  top: 0;
  bottom: 0;

  ${({ height }) =>
    !height ? 'width: 100%; height: auto;' : 'height: 100%; '};
`

// remove
export const nonSelect = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

// shap
export const circle = css`
  ${({ circle }) => circle && `border-radius: 50%;`}
`

export const rounded = css`
  ${({ rounded }) => rounded && `border-radius: 2rem;`}
`

// color
export const background = css`
  ${({ bg }) => bg && `background: ${bg};`}
`

export const color = css`
  ${({ color }) => color && `color: ${color};`}
`

// animation
export const transition = css`
  transition: all 1s ease;
`
